import React, { useRef, useEffect, createRef } from "react"
import { graphql } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import { gsap, ScrollTrigger } from "gsap/all"

import { percentage, randomInteger } from "../utilities/functions"
import BaseImage from "../components/BaseImage"
import BaseSvg from "../components/BaseSvg"
import MotionReveal from "../components/MotionReveal"

import MotionBackground from "../components/MotionBackground"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

export default function FrontPageHero({ items }) {
  const theme = useTheme()
  const ref = useRef(null)
  const refsItems = useRef(items.map(() => createRef(null)))
  const refsHeadingsPins = useRef(items.map(() => createRef(null)))
  const refsHeadingsEnter = useRef(items.map(() => createRef(null)))
  const images = items
    .map(item => {
      return item.items
    })
    .flat()

  useEffect(() => {
    const element = refsItems.current[1].current
    const text = element.querySelectorAll(".text")
    const graphic = element.querySelectorAll(".graphic")
    const scrollTriggers = []
    const timelineText = gsap.timeline({ paused: true })
    const timelineGraphic = gsap.timeline({ paused: true })

    timelineText
      .fromTo(
        text,
        {
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        },
        {
          clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
          ease: "none",
        },
        0.5
      )

    timelineGraphic
      .fromTo(
        graphic,
        {
          y: "-50vh",
        },
        {
          y: "50vh",
        },
        0
      )

    const scrollTriggerText = ScrollTrigger.create({
      trigger: element,
      animation: timelineText,
      end: "bottom center",
      scrub: true,
      pin: text,
      pinSpacing: false,
      anticipatePin: 1,
    })

    scrollTriggers.push(scrollTriggerText)

    const scrollTriggerGraphic = ScrollTrigger.create({
      trigger: element,
      animation: timelineGraphic,
      scrub: true,
    })

    scrollTriggers.push(scrollTriggerGraphic)

    return () => {
      scrollTriggers.forEach(scrollTrigger => scrollTrigger.kill())
    }
  }, [])

  css.section = css`
    position: relative;
  `

  css.content = css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `

  css.media = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: calc((6016 / 1080) * 100vh);
    height: 100vh;
  `

  css.item = css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    mix-blend-mode: difference;
  `

  css.container = css`
    ${theme.mixins.container.xl}
    width: 100%;
    max-width: 1520px;
  `

  css.grid = css`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: ${theme.grid.gutter};
    ${"" /* grid-row-gap: ${theme.grid.gutter}; */}

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
      ${"" /* grid-row-gap: 90px; */}
    }
  `

  css.gridItem = css`
    grid-column: 1;

    &:nth-of-type(1) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1 / 10;
      }
    }

    &:nth-of-type(2) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 1 / 5;
        grid-row: 2;
      }
    }

    &:nth-of-type(3) {
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: 9 / 13;
        grid-row: 2;
      }
    }
  `

  css.heading = css`
    margin: 0;
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "80px",
        toSize: "180px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.bold};
    line-height: 1;
    white-space: pre-wrap;
    color: ${theme.colors.typeAndTixel.oneDifference};
    mix-blend-mode: difference;
  `

  css.text = css`
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "18px",
        toSize: "20px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    line-height: 1.625;
    color: #e5ddd4;
  `

  css.graphic = css`
    display: none;

    @media (min-width: ${theme.breakpoints.tablet}) {
      display: block;
      width: 120px;
      height: auto;
      color: ${theme.colors.typeAndTixel.oneDifference};
    }
  `

  return (
    <MotionBackground
      trigger={ref}
      disableLeave={true}
      color={theme.colors.global.light}
    >
      <section ref={ref} css={css.section} className="panel">
        <div css={css.media} className="media">
          {images.map((image, index) => {
            const distance = randomInteger(25, 100)
            const duration = randomInteger(40, 60)

            return (
              <div key={index}>
                <FrontPageHeroImage
                  className="figure"
                  {...image}
                  index={index}
                  distance={distance}
                  duration={duration}
                  trigger={ref}
                />
                <FrontPageHeroImage
                  className="figure"
                  {...image}
                  index={index}
                  distance={distance}
                  duration={duration}
                  trigger={ref}
                  clone={true}
                />
              </div>
            )
          })}
        </div>
        {items.map((item, index) => (
          <div
            key={index}
            css={css.item}
            ref={refsItems.current[index]}
            className="item"
          >
            <div css={css.container}>
              <div css={css.grid}>
                <div css={css.gridItem}>
                  <MotionReveal
                    trigger={refsItems.current[index]}
                    pin={refsHeadingsPins.current[index]}
                    headingEnter={refsHeadingsEnter.current[index]}
                    markers={false}
                  >
                    <div ref={refsHeadingsPins.current[index]}>
                      <h1
                        ref={refsHeadingsEnter.current[index]}
                        css={css.heading}
                        dangerouslySetInnerHTML={{
                          __html: item.heading,
                        }}
                      />
                    </div>
                  </MotionReveal>
                </div>
                <div css={css.gridItem}>
                  {index === 1 && (
                    <div css={css.text} className="text">
                      We combine insight with strategy to design identities,
                      experiences + campaigns that build brand love.
                    </div>
                  )}
                </div>
                <div css={css.gridItem}>
                  {index === 1 && (
                    <BaseSvg
                      css={css.graphic}
                      className="graphic"
                      src="logo-element"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    </MotionBackground>
  )
}

function FrontPageHeroImage(props) {
  const {
    image,
    top,
    left,
    width,
    height,
    index,
    clone,
    distance,
    duration,
    trigger,
    ...other
  } = props
  const ref = useRef()
  const refImage = useRef()
  const refContainer = useRef()
  const canvasWidth = 6016
  const canvasHeight = 1080
  // const distance = useRef(randomInteger(25, 100))
  // const duration = useRef(randomInteger(40, 60))

  useEffect(() => {
    const element = ref.current
    const elementContainer = refContainer.current
    const timeline = gsap.timeline({ repeat: -1 })

    timeline
      .to(
        element,
        {
          x: () => -Math.abs((canvasWidth / canvasHeight) * window.innerHeight),
          duration: 60,
          ease: "none",
        },
        0
      )
      .fromTo(
        elementContainer,
        {
          xPercent: !clone ? 0 : duration * 5,
        },
        {
          xPercent: !clone ? duration * -5 : 0,
          duration: 60,
          ease: "none",
        },
        0
      )

    const scrollTrigger = ScrollTrigger.create({
      animation: timeline,
      trigger: trigger.current,
      toggleActions: "play pause resume pause",
      invalidateOnRefresh: true,
    })
  }, [])

  useEffect(() => {
    const element = refImage.current
    const elementDistance = distance
    let mouseX = 0
    let mouseY = 0
    const viewportCenterX = window.innerWidth / 2
    const viewportCenterY = window.innerHeight / 2

    window.addEventListener("mousemove", e => {
      mouseX = e.x
      mouseY = e.y
    })

    gsap.ticker.add(() => {
      const percentageFromViewportCenterX = mouseX / viewportCenterX
      const percentageFromViewportCenterY = mouseY / viewportCenterY

      gsap.to(element, {
        x: percentageFromViewportCenterX * -elementDistance,
        y: percentageFromViewportCenterY * -elementDistance,
        duration: 1,
        overwrite: true,
      })
    })
  }, [])

  css.figure = css`
    position: absolute;
    top: ${percentage(top, canvasHeight, "%")};
    left: ${percentage(!clone ? left : left + canvasWidth, canvasWidth, "%")};
    width: ${percentage(width, canvasWidth, "%")};
    margin: 0;

    &:before {
      content: "";
      display: block;
      padding-bottom: ${percentage(height, width, "%")};
    }
  `

  css.image = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `

  return (
    <figure ref={ref} css={css.figure} {...other}>
      <div ref={refContainer} css={css.image}>
        <div ref={refImage} css={css.image}>
          <BaseImage src={image.src} css={css.image} loading="eager" />
        </div>
      </div>
    </figure>
  )
}

export const query = graphql`
  fragment FrontPageHero on DataJsonComponentsProps {
    FrontPageHero {
      items {
        heading
        items {
          image {
            src {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          top
          left
          width
          height
        }
      }
    }
  }
`
